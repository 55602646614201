// references to Blog Posts describing my experiences, written in markdown

export const blogPosts = [
    {
        id: 2,
        title: "LLM game where you play God",
        date: "23 July 2023",
        description: "We made a game",
        link: "https://www.godswork.live/",
        moneySpent: 300,
        moneyEarned: 0,
        humanConnections: 500,
        previewImageLink: "https://i.imgur.com/dZ1xieZ.jpg",
        slug: 'godswork'
    },
    {
        id: 0,
        title: "Ukranian refugee donation map",
        date: "26 Feb 2022",
        description: "",
        link: "https://www.instagram.com/p/CahcYxisMlg/",
        moneySpent: 0,
        moneyEarned: 0,
        humanConnections: 170468,
        previewImageLink: "https://i.imgur.com/5NqKcD0.jpg",
        slug: 'ukraine_aid'
    },

];
