import React from 'react';
import xmlbuilder from 'xmlbuilder';
import { blogPosts } from './data';

const RSSFeed = () => {
    const rss = xmlbuilder.create('rss', { version: '1.0', encoding: 'UTF-8' });
    rss.att('version', '2.0');

    const channel = rss.ele('channel');
    channel.ele('title', 'Your Blog Title');
    channel.ele('link', 'https://www.yourblog.com/');
    channel.ele('description', 'Your Blog Description');

    blogPosts.forEach(post => {
        const item = channel.ele('item');
        item.ele('title', post.title);
        item.ele('link', post.link);
        item.ele('description', post.description);
        // Convert date to the required format
        const pubDate = new Date(post.date).toUTCString();
        item.ele('pubDate', pubDate);
    });

    const xmlString = rss.end({ pretty: true });

    return (
        <pre>
            {xmlString}
        </pre>
    );
};

export default RSSFeed;
