import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import {useParams} from 'react-router-dom';
import "./MarkdownPost.css";

import {Container, Box, Typography, IconButton, Fab} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

const renderImage = (props) => {
    const frames = ['frame-1', 'frame-2', 'frame-3'];
    const randomFrame = frames[Math.floor(Math.random() * frames.length)];
    return (
        <div className={"frame-3"}>
            <img {...props} style={{ maxHeight: '300px', width: 'auto', ...props.style }} />
        </div>
    );
};

const MarkdownPost = () => {
    const {slug} = useParams();
    const [markdownContent, setMarkdownContent] = useState('');
    const [showScrollTop, setShowScrollTop] = useState(false);

    useEffect(() => {
        import(`../blog_posts/${slug}.md`)
            .then(module => {
                fetch(module.default)
                    .then(response => response.text())
                    .then(content => {
                        setMarkdownContent(content);
                    });
            })
            .catch(error => {
                console.error("Failed to load markdown file:", error);
                setMarkdownContent(null);
            });

        const checkScrollTop = () => {
            if (!showScrollTop && window.pageYOffset > 400) {
                setShowScrollTop(true);
            } else if (showScrollTop && window.pageYOffset <= 400) {
                setShowScrollTop(false);
            }
        };

        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [slug, showScrollTop]);

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    if (markdownContent === null) return <div>Post not found</div>;
    return (
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" minHeight="100vh">
            {/*arrow that takes the user back to index*/}
            <a href="/" className="back-button" aria-label="back">
                <ArrowBack/> Back to index
            </a>
            <Container maxWidth="md">
                <Box display="flex" flexDirection="column">
                    <Typography component="div">
                        <div className="markdown-content">
                            <ReactMarkdown skipHtml={false} allowDangerousHtml components={{ img: renderImage }}>
                                {markdownContent}
                            </ReactMarkdown>
                        </div>
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default MarkdownPost;