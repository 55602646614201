import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, ChevronUp, X } from 'lucide-react';
// Remove Fireworks import
import './IncidentCounterOutput.css';
import sashaHeadImage from './days_since_incident_img/sasha-head.jpg';
import sashaBikeImage from './days_since_incident_img/sasha-bike2.jpg';
import anyaBikeImage from './days_since_incident_img/anya-bike.jpg';
import anyaShittingImage from './days_since_incident_img/anna-before-shitting.jpg';
import anyaSusPukeImage from './days_since_incident_img/anya-sus-puke2.png';
import allPukeImage from './days_since_incident_img/all-puke.jpg';
import annaBruiseImage from './days_since_incident_img/anna-bruise.jpeg';
import ReactGA from 'react-ga4';

const IncidentCounter = () => {
    const [days, setDays] = useState(0);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showCar, setShowCar] = useState(false);
    const [showTitle, setShowTitle] = useState(false);
    const [showCelebration, setShowCelebration] = useState(false);
    const containerRef = useRef(null);

    const incidentHistory = [
        {
            date: '2024-10-20',
            description: 'Саша ебнулся головой',
            photo: sashaHeadImage
        },
        {
            date: '2024-10-19',
            description: 'Аня блевала с собаками',
            photo: anyaSusPukeImage
        },
        {
            date: '2024-10-18',
            description: 'У Ани на ноге появился млечный путь',
            photo: annaBruiseImage
        },
        {
            date: '2024-10-10',
            description: 'Саша ебнулся с байка',
            photo: sashaBikeImage
        },
        {
            date: '2024-10-10',
            description: 'Веселая Аня в кафе через 10 минут чуть не обосралась в агенстве недвижимости. Или обосралась. История умалчивает.',
            photo: anyaShittingImage
        },
        {
            date: '2024-10-8',
            description: 'Все блевали и какали и смотрели Лост (кроме Ани Сусловой, которая опоздала на 2 недели)',
            photo: allPukeImage
        },
        {
            date: '2024-10-7',
            description: 'Аня с Савой йопнулись с байка',
            photo: anyaBikeImage
        },
        // Add more incidents as needed
    ];

    useEffect(() => {
        // Sort the incidents by date in descending order and get the latest
        const sortedIncidents = [...incidentHistory].sort((a, b) => new Date(b.date) - new Date(a.date));
        const lastIncidentDate = sortedIncidents[0].date;

        const timeDiff = new Date().getTime() - new Date(lastIncidentDate).getTime();
        setDays(Math.floor(timeDiff / (1000 * 3600 * 24)));

        // Remove fireworks initialization
    }, []);

    const resetCounter = () => {
        ReactGA.event({
            category: 'User',
            action: 'Reset Counter',
        });
        
        const today = new Date().toISOString().split('T')[0];
        setShowCelebration(true);
        setTimeout(() => {
            setShowCelebration(false);
        }, 4000); // Hide celebration after 4 seconds
    };

    const toggleHistory = () => {
        setIsHistoryOpen(!isHistoryOpen);
    };

    const openImage = (image) => {
        setSelectedImage(image);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    return (
        <div className="incident-container bg-yellow-400 min-h-screen p-4 relative font-mono overflow-hidden" ref={containerRef}>
            <div className="text-center mb-8">
                <h1 className="text-4xl font-bold mb-4">Дней без происшествий на Бали</h1>
                <p className="text-xl">В — вместительный</p>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
                <div className="text-center mb-4">
                    <h2 className="text-2xl font-bold">Дней без происшествия</h2>
                </div>

                <div className="flex justify-center space-x-1 mb-6">
                    {String(days).padStart(8, '0').split('').map((digit, index) => (
                        <div
                            key={index}
                            className={`w-12 h-16 flex items-center justify-center text-3xl font-bold border border-black ${index < 7 ? 'text-gray-300' : 'text-black'}`}
                        >
                            {digit}
                        </div>
                    ))}
                </div>

                <div className="text-center mb-4">
                    <button
                        onClick={toggleHistory}
                        className="text-blue-600 hover:underline flex items-center justify-center mx-auto"
                    >
                        {isHistoryOpen ? 'Скрыть историю' : 'История происшествий'}
                        {isHistoryOpen ? <ChevronUp className="ml-1" /> : <ChevronDown className="ml-1" />}
                    </button>
                </div>

                {isHistoryOpen && (
                    <div className="mb-4">
                        <h3 className="text-lg font-bold mb-2">История инцидентов:</h3>
                        <ul className="space-y-4">
                            {incidentHistory.map((incident, index) => (
                                <li key={index} className="bg-white rounded-lg shadow p-4">
                                    <div className="flex items-start">
                                        {incident.photo && (
                                            <img
                                                src={incident.photo}
                                                alt={`Incident on ${incident.date}`}
                                                className="w-24 h-24 object-cover rounded mr-4 cursor-pointer"
                                                onClick={() => openImage(incident.photo)}
                                            />
                                        )}
                                        <div>
                                            <p className="font-bold">{incident.date}</p>
                                            <p>{incident.description}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="text-center">
                    <button
                        onClick={resetCounter}
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Топовая поездка!
                    </button>
                </div>
            </div>

            {selectedImage && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={() => setSelectedImage(null)}>
                    <div className="bg-white p-4 rounded-lg shadow-lg relative" onClick={(e) => e.stopPropagation()}>
                        <img src={selectedImage} alt="Incident" className="max-w-md max-h-md object-contain" />
                        <button
                            onClick={() => setSelectedImage(null)}
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                        >
                            <X size={24} />
                        </button>
                    </div>
                </div>
            )}

            {showCelebration && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="celebration-content text-center animate-drive-in">
                        <span className="text-9xl mb-4">🚗</span>
                        <h2 className="text-6xl font-bold text-red-600">
                            You've been DK-ed!
                        </h2>
                    </div>
                    <div className="confetti-container">
                        {[...Array(50)].map((_, i) => (
                            <div key={i} className="confetti" style={{
                                left: `${Math.random() * 100}%`,
                                animationDelay: `${Math.random() * 4}s`,
                            }}></div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default IncidentCounter;
