import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const Timeline = ({ blogPosts }) => {
    return (
        <Grid container spacing={2}>
            {blogPosts.map((post, index) => (
                <Grid item xs={12} key={index}>
                    <Link to={`/posts/${post.slug}`} style={{ textDecoration: 'none' }}>
                        <Card>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {post.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {post.description}
                                    </Typography>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            {format(new Date(post.date), 'MMMM dd, yyyy')}
                                        </Typography>
                                    </Box>
                                </CardContent>
                                {post.previewImageLink && (
                                    <Box position="relative" m={2}>
                                        <CardMedia
                                            component="img"
                                            image={post.previewImageLink}
                                            alt={post.title}
                                            sx={{ width: 80, height: 80, objectFit: 'cover' }}
                                        />
                                        <Box
                                            position="absolute"
                                            top={0}
                                            left={0}
                                            width="100%"
                                            height="100%"
                                            bgcolor="rgba(0, 0, 0, 0.4)"
                                            sx={{
                                                transition: '0.3s',
                                                '&:hover': {
                                                    bgcolor: 'rgba(0, 0, 0, 0.1)'
                                                }
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Card>
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
}

export default Timeline;
